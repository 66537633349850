import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import FindLO from "../../components/FindLO";
import Calculators from "../../components/Calculators";
import ProductDetails from "../../components/ProductDetails";
import Accent from "../../components/Accent";
import HTMLContent from "../../components/Content";

import hero from "./images/hero.jpg";
import icon from "./Icons_VA_active.png";

import styles from "./styles.module.less";

const VALoans = ({ data }) => {
    const header = (
        <Header active={["current-homeowner", "home-loans", "va-loans"]} />
    );
    const intro = "VA Loans";
    const subhead = (
        <div>
            Helping veterans come back
            <br /> to a place that feels like home.
        </div>
    );

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>VA Loans - Home Loans | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="VA home loans are for currently serving military members and require no down payment. Want to find out if you qualify? Contact us today."
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <div className={styles.productHero}>
                <div
                    className={styles.HeroImg}
                    style={{ backgroundImage: `url(${hero})` }}>
                    <div className={styles.heroCopy}>
                        <div className={styles.iconContainer}>
                            <img
                                src={icon}
                                alt="VA Loan | Bay Equity Home Loans"
                            />
                        </div>
                        <div className={styles.copy}>
                            <div className={styles.header}>{intro}</div>
                            <Accent />
                            <HTMLContent
                                className={styles.subhead}
                                content={subhead}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ProductDetails title={"What is a VA home loan?"} border={true}>
                <p>
                    A VA home loan is a mortgage guaranteed by the United States
                    Department of Veteran Affairs, designed to help military
                    members move into the homes of their dreams.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are some VA home loan benefits?"}
                border={true}>
                <ul>
                    <li>No down payment for purchases</li>
                    <li>No prepayment penalties</li>
                    <li>Lower average interest rates</li>
                    <li>No monthly mortgage insurance</li>
                    <li>Limitations on buyer’s closing costs</li>
                    <li>Cash-out loans</li>
                    <li>Interest rate reduction loans</li>
                </ul>
            </ProductDetails>
            <ProductDetails
                title={"What are the current rates of a VA loan?"}
                border={true}>
                <p>
                    Interest rates on VA home loans change all the time based on
                    market trends. While we can’t guarantee your specific rate
                    here and now,{" "}
                    <a
                        href="/find-your-loan-officer"
                        style={{ color: "#FD8A25" }}>
                        talk to one of our loan officers
                    </a>{" "}
                    to lock in your exact interest rate.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How do I qualify for VA home loan eligibility?"}>
                <p>
                    Qualifying for a VA loan takes more than military service.
                    Although it’s definitely a base requirement, VA home loan
                    requirements say you will need to have served the equivalent
                    of one of the following terms:
                </p>
                <ul>
                    <li>
                        Honorable discharge after 90 days of active service
                        during wartime
                    </li>
                    <li>
                        Honorable discharge after 181 days of active service
                        during peacetime
                    </li>
                    <li>
                        Two-year requirement of service enlisted after 9/7/1980
                    </li>
                    <li>
                        Six or more years of service in the National Guard or
                        Reserves
                    </li>
                </ul>
                <p>
                    The spouse of a service member who has died in the line of
                    duty or as a result of a service-related disability is also
                    eligible for this mortgage program.{" "}
                    <a
                        href="/find-your-loan-officer"
                        style={{ color: "#FF8A00" }}>
                        Talk to one of our loan officers
                    </a>{" "}
                    about your VA home loan options today.
                    <br />
                    <br />
                    Qualifying also takes into consideration income and credit.
                </p>
            </ProductDetails>
            <Calculators
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
            />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="vh-loans"
                />
            )}
        </Layout>
    );
};

export default VALoans;

export const pageQuery = graphql`
    query VALoansQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["VH Loans"] } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
